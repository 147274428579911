import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { BottleFeedingInterface, BreastFeedingInterface } from "./types";

export const useClinicSlice = createSlice({
  name: "graphState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    doGetActivitiesInMonth: (
      state,
      action: PayloadAction<{
        token: string;
        month: number;
        trackerType: number;
        startDate: string;
        endDate: string;
        callback: () => void;
      }>
    ) => {},
    doGetComparisonOfMilestone: (
      state,
      action: PayloadAction<{
        token: string;
        month: number;
        callback: () => void;
      }>
    ) => {},
    doGetGrowthGraph: (
      state,
      action: PayloadAction<{
        token: string;
        month: number;
        callback: () => void;
      }>
    ) => {},
    doGetSleepGraph: (
      state,
      action: PayloadAction<{
        token: string;
        trackerType: number;
        startDate: string;
        endDate: string;
        month: number;
        callback: () => void;
      }>
    ) => {},
    doGetBreastFeeding: (
      state,
      action: PayloadAction<{
        token: string;
        trackerType: number;
        startDate: string;
        endDate: string;
        month: number;
        callback: () => void;
      }>
    ) => {},
    doGetBottleFeeding: (
      state,
      action: PayloadAction<{
        token: string;
        trackerType: number;
        startDate: string;
        endDate: string;
        month: number;
        callback: () => void;
      }>
    ) => {},
    doGetGrowthHeightChart: (
      state,
      action: PayloadAction<{
        token: string;
        startMonth: string;
        endMonth: string;
        callback: () => void;
      }>
    ) => {},
    doGetGrowthWeightChart: (
      state,
      action: PayloadAction<{
        token: string;
        startMonth: string;
        endMonth: string;
        callback: () => void;
      }>
    ) => {},
    setActivitiesInMonthData: (state, action: PayloadAction<any>) => {
      state.ActivitiesInMonth = action.payload;
      
    },
    setComparisonOfMilestoneData: (state, action: PayloadAction<any>) => {
      state.ComparisonOfMilestone = action.payload;
    },
    setGrowthGraphData: (state, action: PayloadAction<any>) => {
      state.GrowthGraph = action.payload;
    },
    setSleepGraphData: (state, action: PayloadAction<any>) => {
      state.SleepGraph = action.payload;
    },
    setBreastFeedingData: (
      state,
      action: PayloadAction<BreastFeedingInterface>
    ) => {
      state.BreastFeeding = action.payload;
    },
    setBottleFeedingData: (
      state,
      action: PayloadAction<BottleFeedingInterface>
    ) => {
      state.bottleFeeding = action.payload;
    },
    setGrowthHeightChartData: (state, action: PayloadAction<any>) => {
      state.growthHeightChart = action.payload;
    },
    setGrowthWeightChartData: (state, action: PayloadAction<any>) => {
      state.growthWeightChart = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
