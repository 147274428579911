import Api from "./Api";

const ActivityGraph = async (filter: string, token: string) => {
  return Api.get(`/activity/getGraph${filter}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
const ComparisonOfMilestone = async (filter: string, token: string) => {
  return Api.get(`/milestonecategory/get-graph-report${filter}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
const SleepGraph = async (filter: string, token: string) => {
  return Api.get(`/analytics/get/sleep-graph${filter}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
const BreastFeeding = async (filter: string, token: string) => {
  return Api.get(`/analytics/get/breast-feeding-graph${filter}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
const BottleFeeding = async (filter: string, token: string) => {
  return Api.get(`/analytics/get/bottle-feeding-graph${filter}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
const GrowthHeightChart = async (filter: string, token: string) => {
  return Api.get(`/analytics/get/height-growth-graph${filter}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
const GrowthWeightChart = async (filter: string, token: string) => {
  return Api.get(`/analytics/get/weight-growth-graph${filter}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
export {
  ActivityGraph,
  ComparisonOfMilestone,
  SleepGraph,
  BreastFeeding,
  BottleFeeding,
  GrowthHeightChart,
  GrowthWeightChart,
};
