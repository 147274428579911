import { call, delay, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import {
  ActivityGraph,
  BottleFeeding,
  BreastFeeding,
  ComparisonOfMilestone,
  GrowthHeightChart,
  GrowthWeightChart,
  SleepGraph,
} from "../utils/request";
import CatchBlockFunction from "../CatchError";

export function* doGetActivityGraphRequest(action: {
  payload: {
    token: string;
    month: number;
    trackerType: number;
    startDate: string;
    endDate: string;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      ActivityGraph,
      `?month=${action.payload.month}&trackerType=${action.payload.trackerType}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`,
      action.payload.token
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      console.log(response.data.message);
      return;
    }
    yield put(actions.setActivitiesInMonthData(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetComparisonOfMilestoneRequest(action: {
  payload: { token: string; month: number; callback: any };
}) {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      ComparisonOfMilestone,
      `?month=${action.payload.month}`,
      action.payload.token
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      console.log(response.data.message);

      return;
    }
    yield put(actions.setComparisonOfMilestoneData(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doGetBreastFeedingRequest(action: {
  payload: {
    token: string;
    trackerType: number;
    startDate: string;
    endDate: string;
    month: number;
    callback: any;
  };
}) {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      BreastFeeding,
      `?trackerType=${action.payload.trackerType}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}&month=${action.payload.month}`,
      action.payload.token
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      console.log(response.data.message);

      return;
    }
    yield put(actions.setBreastFeedingData(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetBottleFeedingRequest(action: {
  payload: {
    token: string;
    trackerType: number;
    startDate: string;
    endDate: string;
    month: number;
    callback: any;
  };
}) {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      BottleFeeding,
      `?trackerType=${action.payload.trackerType}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}&month=${action.payload.month}`,
      action.payload.token
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      console.log(response.data.message);

      return;
    }
    yield put(actions.setBottleFeedingData(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetGrowthHeightChartRequest(action: {
  payload: {
    token: string;
    startMonth: string;
    endMonth: string;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GrowthHeightChart,
      `?&startMonth=${action.payload.startMonth}&endMonth=${action.payload.endMonth}`,
      action.payload.token
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      console.log(response.data.message);

      return;
    }
    yield put(actions.setGrowthHeightChartData(response.data.data.height));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetGrowthWeightChartRequest(action: {
  payload: {
    token: string;
    startMonth: string;
    endMonth: string;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GrowthWeightChart,
      `?&startMonth=${action.payload.startMonth}&endMonth=${action.payload.endMonth}`,
      action.payload.token
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      console.log(response.data.message);

      return;
    }
    yield put(actions.setGrowthWeightChartData(response.data.data.weight));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetSleepGraphRequest(action: {
  payload: {
    token: string;
    trackerType: number;
    startDate: string;
    endDate: string;
    month: number;
    callback: any;
  };
}) {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      SleepGraph,
      `?trackerType=${action.payload.trackerType}&startDate=${action.payload.startDate}&endDate=${action.payload.endDate}&month=${action.payload.month}`,
      action.payload.token
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      console.log(response.data.message);

      return;
    }
    yield put(actions.setSleepGraphData(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* GraphStateRepoSaga() {
  yield takeLatest(actions.doGetActivitiesInMonth, doGetActivityGraphRequest);
  yield takeLatest(
    actions.doGetComparisonOfMilestone,
    doGetComparisonOfMilestoneRequest
  );
  yield takeLatest(actions.doGetSleepGraph, doGetSleepGraphRequest);
  yield takeLatest(actions.doGetBreastFeeding, doGetBreastFeedingRequest);
  yield takeLatest(actions.doGetBottleFeeding, doGetBottleFeedingRequest);
  yield takeLatest(
    actions.doGetGrowthHeightChart,
    doGetGrowthHeightChartRequest
  );
  yield takeLatest(
    actions.doGetGrowthWeightChart,
    doGetGrowthWeightChartRequest
  );
}
