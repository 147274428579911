import "./App.css";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { GraphStateRepoSaga } from "./Redux/saga";
import loadable from "@loadable/component";
import {
  sliceKey as GraphStateSliceKey,
  reducer as GraphStateReducer,
} from "./Redux/slice";
import { BrowserRouter, Route, Routes } from "react-router-dom";
const ComparisonOfMilestone = loadable(() => import("./ComparisonOfMilestone"));
const ActivitiesInMonth = loadable(() => import("./ActivitiesInMonth"));
const GrowthBoyWeight = loadable(() => import("./Growth"));
const GrowthHeight = loadable(() => import("./Growth/Height"));
const GrowthGirlHeight = loadable(() => import("./Growth/girl-height"));
const GrowthGirlWeight = loadable(() => import("./Growth/girl-weight"));
const BoyBMI = loadable(() => import("./BMI/Boy"));
const GirlBMI = loadable(() => import("./BMI/Girl"));
const Sleep = loadable(() => import("./Sleep"));
const Feeding = loadable(() => import("./Feeding"));
const SleepEvening = loadable(() => import("./Sleep/evening"));
const Breast = loadable(() => import("./Feeding/Breast"));

function App() {
  useInjectReducer({ key: GraphStateSliceKey, reducer: GraphStateReducer });
  useInjectSaga({ key: GraphStateSliceKey, saga: GraphStateRepoSaga });
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ComparisonOfMilestone />} />
        <Route path="/activities" element={<ActivitiesInMonth />} />
        <Route path="/growth-boy-weight" element={<GrowthBoyWeight />} />
        <Route path="/growth-girl-weight" element={<GrowthGirlWeight />} />
        <Route path="/growth-boy-height" element={<GrowthHeight />} />
        <Route path="/growth-girl-height" element={<GrowthGirlHeight />} />
        <Route path="/morning-sleep" element={<Sleep />} />
        <Route path="/evening-sleep" element={<SleepEvening />} />
        <Route path="/bottle-feeding" element={<Feeding />} />
        <Route path="/breast-feeding" element={<Breast />} />
        <Route path="/boy-bmi" element={<BoyBMI />} />
        <Route path="/girl-bmi" element={<GirlBMI />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
