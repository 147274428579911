import { GraphState } from ".";

export const initialState: GraphState = {
  ActivitiesInMonth: { date: [], data: [] },
  ComparisonOfMilestone: [],
  GrowthGraph: [],
  SleepGraph: { morningData: [], eveningData: [], date: [] },
  BreastFeeding: {
    date: [],
    rightSideData: [],
    leftSideData: [],
  },
  bottleFeeding: {
    date: [],
    data: [],
  },
  loading: true,
  growthHeightChart: [],
  growthWeightChart: [],
};
